import "@/styles/globals.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import type { AppProps } from "next/app";
import Head from "next/head";

const queryClient = new QueryClient();

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <main className="max-w-screen-2xl m-auto min-h-screen relative">
        {children}
      </main>
    </>
  );
};

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>User Story Coach</title>
        <meta
          name="description"
          content="A tool to help you write better user stories."
        />
        {/* <link rel="icon" href="/favicon.ico" /> */}
        <meta property="og:title" content="User Story Coach" />
        <meta
          property="og:description"
          content="A tool to help you write better user stories."
        />
        <meta
          property="og:image"
          content={`${process.env.NEXT_PUBLIC_BASE_URL}/og-image.jpg`}
        />
      </Head>
      <QueryClientProvider client={queryClient}>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </QueryClientProvider>
    </>
  );
}
